import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';            
import { Row, Col, Button, Form, Select } from 'antd';
import { DATE_FORMAT_FR } from '../../../constants/Generic';
import { selectUserPreferences, updateUserPreferences } from '../../../redux/slices/app/user.slice';
import { incrementReRenderGantt } from '../../../redux/slices/app/planning.slice';
import CustomColorPicker from '../../utils/CustomColorPicker';


const defaultParameters = {
    gridBackgroundColor: '#f8f8f8',
    gridTextColor: '#2a2f35',
    dateFormat: DATE_FORMAT_FR,
    dateSeparator: '/'
};

// This component is used for customizing Gantt appearance or behaviour

const GanttPreferencesGlobal = ({ onOk, onCancel }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { t } = useTranslation('translation', { keyPrefix: 'gantt_setting' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [loading, setLoading] = useState(false);
    const [oldParameters, setOldParameters] = useState(defaultParameters);
    const [parameters, setParameters] = useState(defaultParameters);
    // colors
    // const [showGridBackgroundColorPicker, setShowGridBackgroundColorPicker] = useState(false);
    // const [showGridTextColorPicker, setShowGridTextColorPicker] = useState(false);
    const [generatedDateFormat, setGeneratedDateFormat] = useState('YYYY-MM-DD');
    const userPreferences = useSelector(selectUserPreferences);

    const updateGanttParameters = () => {
        setLoading(true);
        dispatch(updateUserPreferences({
            gantt_style: { ...(userPreferences.gantt_style ?? {}), gridHeaderColors: parameters },
            dateFormat: parameters.dateFormat,
            dateSeparator: parameters.dateSeparator
        }))
            .then(() => {
                setLoading(false);
                dispatch(incrementReRenderGantt())
                onOk();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const submitForm = () => {
        if (loading) {
            return;
        }
        updateGanttParameters();
    };

    useEffect(() => {
        if (userPreferences?.gantt_style?.gridHeaderColors) {
            setParameters(() => {
                const newValue = {
                    ...defaultParameters,
                    ...userPreferences?.gantt_style?.gridHeaderColors,
                    dateFormat: userPreferences.dateFormat ?? DATE_FORMAT_FR,
                    dateSeparator: userPreferences.dateSeparator ?? '/',
                };
                setOldParameters(newValue);
                return newValue;
            });
        }
    }, [userPreferences]);

    const dateFormatOptions = [
        {
            label: `${t('DD')} ${t('MM')} ${t('YYYY')}`,
            value: 'DD_MM_YYYY',
        },
        {
            label: `${t('MM')} ${t('DD')} ${t('YYYY')}`,
            value: 'MM_DD_YYYY',
        },
        {
            label: `${t('YYYY')} ${t('MM')} ${t('DD')}`,
            value: 'YYYY_MM_DD',
        },
    ];
    const dateSeparatorOptions = [
        {
            label: '/',
            value: '/',
        },
        {
            label: '-',
            value: '-',
        },
        {
            label: '.',
            value: '.',
        },
        {
            label: ',',
            value: ',',
        },
        {
            label: t('space'),
            value: ' ',
        },
    ];

    useEffect(() => {
        setGeneratedDateFormat(parameters.dateFormat.replaceAll('_', parameters.dateSeparator))
    }, [parameters.dateSeparator, parameters.dateFormat])

    /* eslint-disable react/prop-types */

    return (
        <Form
            form={form}
            layout="horizontal"
            name="planning_parameter_display"
            onFinish={submitForm}
            initialValues={parameters}
            style={{ height: '500px' }}
        >
            <div style={{ height: '450px', overflowY: 'auto' }}>
                <Row className="flex items-center">
                    <Col span={5}>
                        <label>{t('gantt_table_header')} </label>
                    </Col>
                    <Col span={3}>
                    <CustomColorPicker
                            color={parameters.gridBackgroundColor}
                            onChange={(color) => setParameters((old) => ({ ...old, gridBackgroundColor: color }))}
                            label={t('gantt_header_background')}
                        />
                    </Col>
                    <Col span={3}>
                    <CustomColorPicker
                            color={parameters.gridTextColor}
                            onChange={(color) => setParameters((old) => ({ ...old, gridTextColor: color }))}
                            label={t('text')}
                        />
                    </Col>
                </Row>
                <Row className="flex items-center">
                    <Col span={5}>
                    <label>{t('date_format')}</label>
                    </Col>
                    <Col span={5}  className='mr-2'>
                        <label className="pr-2">{t('date_format')}</label>
                        <Form.Item className='w-full'>
                            <Select value={parameters.dateFormat} onChange={(val) => setParameters((old) => ({ ...old, dateFormat: val }))} options={dateFormatOptions}/>
                            
                        </Form.Item>
                    </Col>
                    <Col span={3} className='mr-2'>
                        <label className="pr-2">{t('date_separator')}</label>
                        <Form.Item className='w-full'>
                        <Select value={parameters.dateSeparator}  onChange={(val) => setParameters((old) => ({ ...old, dateSeparator: val }))} options={dateSeparatorOptions}/>
                        </Form.Item>
                    </Col>
                    <Col>
                                       {t('example')}: {moment().format(generatedDateFormat)}
                    </Col>
                </Row>
            </div>

            <Row className="mt-3">
                <Col span={24} className="text-right">
                    <Button
                        htmlType="button"
                        className="mr-2"
                        onClick={() => {
                            setParameters(oldParameters);
                            onCancel();
                        }}
                    >
                        {generalTranslation('cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {generalTranslation('save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

GanttPreferencesGlobal.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
export default GanttPreferencesGlobal;
